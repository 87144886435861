import { getCoursesConfig } from './courses'
import { getLearnConfig } from './learn'

const configurations: Record<string, string | number | null> = {
    ...getCoursesConfig(),
    ...getLearnConfig(),
    'config-cat-key': (process.env.CONFIG_CAT_KEY as string) || null,
    env: (process.env.ENVIRONMENT as string) || null
}

export default function config(name: string, defaultValue = null) {
    if (name in configurations) return configurations[name]
    else return defaultValue
}
